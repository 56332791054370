import { useCallback } from "react";
import { redirect } from "react-router-dom";

export enum UserRoles {
  admin = "admin",
  advisor = "advisor",
  eventOperator = "eventOperator",
  eventAdmin = "eventAdmin",
  bookingAdmin = "bookingAdmin",
  secretary = "secretary",
}

export enum Actions {
  create = "create",
  read = "read",
  update = "update",
  delete = "delete",
}

export enum Subjects {
  users = "users",
  availabilities = "availabilities",
  bookings = "bookings",
  blocked = "blocked",
  capabilities = "capabilities",
  courses = "courses",
  places = "places",
  areas = "areas",
  events = "events",
  subscriptions = "subscriptions",
  operators = "operators",
  participations = "participations",
  students = "students",
  statistics = "statistics",
  middlewareCalls = "middlewareCalls",
}

export type RolesPermissions = Record<
  UserRoles,
  {
    name: UserRoles;
    permissions: Record<Subjects, Record<Actions, boolean>>;
  }
>;

const userRoles: RolesPermissions = {
  admin: {
    name: UserRoles.admin,
    permissions: {
      users: {
        read: true,
        create: true,
        delete: false,
        update: true,
      },
      availabilities: {
        read: true,
        create: true,
        update: true,
        delete: true,
      },
      bookings: {
        read: true,
        create: true,
        update: true,
        delete: true,
      },
      blocked: {
        read: true,
        create: true,
        update: true,
        delete: true,
      },
      capabilities: {
        read: true,
        create: true,
        delete: true,
        update: true,
      },
      courses: {
        read: true,
        create: true,
        delete: true,
        update: true,
      },
      places: {
        read: true,
        create: true,
        delete: true,
        update: true,
      },
      areas: {
        read: true,
        create: true,
        delete: true,
        update: true,
      },
      events: {
        read: true,
        create: true,
        delete: true,
        update: true,
      },
      subscriptions: {
        read: true,
        create: true,
        delete: false,
        update: true,
      },
      operators: {
        read: true,
        create: true,
        delete: true,
        update: true,
      },
      participations: {
        read: true,
        create: true,
        delete: true,
        update: true,
      },
      students: {
        read: true,
        create: false,
        delete: false,
        update: false,
      },
      statistics: {
        read: true,
        create: false,
        update: false,
        delete: false,
      },
      middlewareCalls: {
        read: true,
        create: true,
        update: true,
        delete: true,
      },
    },
  },
  advisor: {
    name: UserRoles.advisor,
    permissions: {
      users: {
        read: false,
        create: false,
        update: false,
        delete: false,
      },
      availabilities: {
        read: true,
        create: true,
        update: true,
        delete: true,
      },
      bookings: {
        read: true,
        create: true,
        update: true,
        delete: true,
      },
      blocked: {
        read: true,
        create: false,
        update: false,
        delete: false,
      },
      capabilities: {
        read: true,
        create: false,
        update: false,
        delete: false,
      },
      courses: {
        read: true,
        create: false,
        update: false,
        delete: false,
      },
      places: {
        read: false,
        create: false,
        update: false,
        delete: false,
      },
      areas: {
        read: false,
        create: false,
        update: false,
        delete: false,
      },
      events: {
        read: true,
        create: false,
        update: false,
        delete: false,
      },
      subscriptions: {
        read: true,
        create: true,
        update: true,
        delete: true,
      },
      operators: {
        read: false,
        create: false,
        update: false,
        delete: false,
      },
      participations: {
        read: true,
        create: true,
        update: true,
        delete: true,
      },
      students: {
        read: false,
        create: false,
        update: false,
        delete: false,
      },
      statistics: {
        read: true,
        create: false,
        update: false,
        delete: false,
      },
      middlewareCalls: {
        read: false,
        create: false,
        update: false,
        delete: false,
      },
    },
  },
  eventOperator: {
    name: UserRoles.eventOperator,
    permissions: {
      users: {
        read: false,
        create: false,
        update: false,
        delete: false,
      },
      availabilities: {
        read: false,
        create: false,
        update: false,
        delete: false,
      },
      bookings: {
        read: false,
        create: false,
        update: false,
        delete: false,
      },
      blocked: {
        read: false,
        create: false,
        update: false,
        delete: false,
      },
      capabilities: {
        read: false,
        create: false,
        update: false,
        delete: false,
      },
      courses: {
        read: false,
        create: false,
        update: false,
        delete: false,
      },
      places: {
        read: false,
        create: false,
        update: false,
        delete: false,
      },
      areas: {
        read: false,
        create: false,
        update: false,
        delete: false,
      },
      events: {
        read: true,
        create: false,
        update: false,
        delete: false,
      },
      subscriptions: {
        read: true,
        create: true,
        update: true,
        delete: false,
      },
      operators: {
        read: false,
        create: false,
        update: false,
        delete: false,
      },
      participations: {
        read: true,
        create: true,
        update: true,
        delete: true,
      },
      students: {
        read: false,
        create: true,
        delete: true,
        update: true,
      },
      statistics: {
        read: true,
        create: false,
        update: false,
        delete: false,
      },
      middlewareCalls: {
        read: true,
        create: true,
        update: true,
        delete: true,
      },
    },
  },
  eventAdmin: {
    name: UserRoles.eventAdmin,
    permissions: {
      users: {
        read: true,
        create: true,
        update: true,
        delete: false,
      },
      availabilities: {
        read: false,
        create: false,
        update: false,
        delete: false,
      },
      bookings: {
        read: false,
        create: false,
        update: false,
        delete: false,
      },
      blocked: {
        read: false,
        create: false,
        update: false,
        delete: false,
      },
      capabilities: {
        read: false,
        create: false,
        update: false,
        delete: false,
      },
      courses: {
        read: false,
        create: false,
        update: false,
        delete: false,
      },
      places: {
        read: true,
        create: false,
        update: false,
        delete: false,
      },
      areas: {
        read: true,
        create: false,
        update: false,
        delete: false,
      },
      events: {
        read: true,
        create: true,
        update: true,
        delete: true,
      },
      subscriptions: {
        read: true,
        create: true,
        update: true,
        delete: false,
      },
      operators: {
        read: true,
        create: true,
        update: true,
        delete: true,
      },
      participations: {
        read: true,
        create: true,
        update: true,
        delete: true,
      },
      students: {
        read: false,
        create: true,
        delete: true,
        update: true,
      },
      statistics: {
        read: true,
        create: false,
        update: false,
        delete: false,
      },
      middlewareCalls: {
        read: true,
        create: true,
        update: true,
        delete: true,
      },
    },
  },
  bookingAdmin: {
    name: UserRoles.bookingAdmin,
    permissions: {
      users: {
        read: true,
        create: false,
        delete: false,
        update: true,
      },
      availabilities: {
        read: true,
        create: true,
        update: true,
        delete: true,
      },
      bookings: {
        read: true,
        create: true,
        update: true,
        delete: true,
      },
      blocked: {
        read: true,
        create: true,
        update: true,
        delete: true,
      },
      capabilities: {
        read: true,
        create: true,
        delete: true,
        update: true,
      },
      courses: {
        read: true,
        create: true,
        delete: true,
        update: true,
      },
      places: {
        read: true,
        create: false,
        delete: false,
        update: false,
      },
      areas: {
        read: true,
        create: false,
        delete: false,
        update: false,
      },
      events: {
        read: false,
        create: false,
        delete: false,
        update: false,
      },
      subscriptions: {
        read: false,
        create: false,
        delete: false,
        update: false,
      },
      operators: {
        read: false,
        create: false,
        delete: false,
        update: false,
      },
      participations: {
        read: false,
        create: false,
        delete: false,
        update: false,
      },
      students: {
        read: false,
        create: false,
        delete: false,
        update: false,
      },
      statistics: {
        read: true,
        create: false,
        update: false,
        delete: false,
      },
      middlewareCalls: {
        read: true,
        create: true,
        update: true,
        delete: true,
      },
    },
  },
  secretary: {
    name: UserRoles.secretary,
    permissions: {
      users: {
        read: false,
        create: false,
        delete: false,
        update: false,
      },
      availabilities: {
        read: true,
        create: true,
        update: true,
        delete: true,
      },
      bookings: {
        read: true,
        create: true,
        update: true,
        delete: true,
      },
      blocked: {
        read: true,
        create: true,
        update: true,
        delete: true,
      },
      capabilities: {
        read: true,
        create: false,
        delete: false,
        update: false,
      },
      courses: {
        read: true,
        create: false,
        delete: false,
        update: false,
      },
      places: {
        read: false,
        create: false,
        delete: false,
        update: false,
      },
      areas: {
        read: false,
        create: false,
        delete: false,
        update: false,
      },
      events: {
        read: true,
        create: false,
        delete: false,
        update: false,
      },
      subscriptions: {
        read: true,
        create: true,
        delete: true,
        update: true,
      },
      operators: {
        read: false,
        create: false,
        delete: false,
        update: false,
      },
      participations: {
        read: true,
        create: true,
        delete: true,
        update: true,
      },
      students: {
        read: false,
        create: false,
        delete: false,
        update: false,
      },
      statistics: {
        read: true,
        create: false,
        update: false,
        delete: false,
      },
      middlewareCalls: {
        read: false,
        create: false,
        update: false,
        delete: false,
      },
    },
  },
};

export const protectedRouteLoader = async (entity?: Subjects) => {
  const token = await localStorage.getItem("token");
  const user = await JSON.parse(localStorage.getItem("user") || "{}");

  if (!token) {
    throw redirect("/login");
  }

  // @ts-ignore
  if (entity && !userRoles?.[user?.role]?.permissions?.[entity]?.read) {
    throw new Error("Unauthorized");
  }

  return { user };
};

export const useAuth = () => {
  const saveAuthUser = (user: any) => {
    localStorage.setItem("user", JSON.stringify(user));
  };

  const saveToken = (token: string) => {
    localStorage.setItem("token", token);
  };

  const getToken = () => {
    return localStorage.getItem("token");
  };

  const getAuthUser = () => {
    return JSON.parse(localStorage.getItem("user") || "{}");
  };

  const deleteAuthData = () => {
    localStorage.removeItem("user");
    localStorage.removeItem("token");
  };

  const checkUserPermission = useCallback(
    (entity: Subjects, action: Actions) => {
      const user = getAuthUser();
      // @ts-ignore
      return userRoles[user?.role]?.permissions?.[entity][action];
    },
    [],
  );

  return {
    saveAuthUser,
    saveToken,
    getAuthUser,
    getToken,
    deleteAuthData,
    checkUserPermission,
  };
};
